.main-navigation__menu-btn {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
    
  }
  
  .main-navigation__menu-btn span {
    display: block;
    width: 3rem;
    height: 2.5px;
    background: #000000;
  }
  .main-navigation__logo-image{
    width:162px;
    height:80px;
  }
  .main-navigation__title {
    color: #000000;
  }
  
  .main-navigation__title a {
    text-decoration: none;
    color: #000000;
  }
  
  .main-navigation__header-nav {
    display: none;
  }
  
  .main-navigation__drawer-nav {
    height: 100%;
  }
  
  @media (min-width: 768px) {
    .main-navigation__menu-btn {
      display: none;
    }
  
    .main-navigation__header-nav {
      display: block;
      margin-right: 1.5rem;
    }
  }

  .main-navigation__logo-image{
    width: 80px;
    height: 80px;
    max-height: 80px;
    margin-top: 0.5rem;
    
  }