.resource-card {
  display: flex;
  flex-direction: column;
  color: black;
  margin: 1rem;
  padding: 0;
  background: var(--colorSecondary);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
  width: 21%;
  height: 300px; /* Fixed height */
}

.resource-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.resource-heading {
  font-size: 1.5rem;
  margin: 0;
  padding: 1rem;
  text-align: center;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.resource-description {
  font-size: 1rem;
  color: #333;
  text-align: center;
  line-height: 1.4;
  margin: 0;
  padding: 0.5rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resource-image {
  height: 150px; /* Fixed height for the image */
  width: 100%;
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

/* Media query for mobile devices */
@media (max-width: 1000px) {
  .resource-card {
    width: 100%; /* Full width on mobile */
    margin: 0.5rem 0; /* Reduce margin */
    height: auto; /* Adjust height to fit content */
  }

  .resource-description {
    display: none; /* Hide description on mobile */
  }
}
