

.add-resource-modal{
    overflow-x: hidden;
}

.resource-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    margin: 1rem;
}

.resource-form h2 {
    font-size: 1.8rem;
    margin: 0rem;
    padding: 1rem;
}

.resource-form input {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--colorPrimary);
}

.resource-form textarea {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--colorPrimary);
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
}

.submit-button, .cancel-button {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
}

.submit-button {
    background: rgb(36, 145, 36);
    color: white;
    border-radius: 0.5rem;
    border-color: black;
}

.cancel-button {
    background: gray;
    color: white;
    border-radius: 0.5rem;
    border-color: black;
}

.resource-form button:hover {
    background: var(--colorPrimary);
}

.resource-form label {
    font-size: 1rem;
    font-weight: bold;
    margin: 0.5rem;
}
