/*edit this file for global styles*/
/*MSU COLORS are 
RED: #d11242
Blue #00447c
Grey #717073

*/
:root {
  --colorPrimary: #00447c;
  --colorPrimaryVariant: #d11242;
  --colorSecondary: #d5bfbf; /* Cards Buttons */
  --colorSecondaryVariant: #00447c; /* Nav Bar, Modal Header*/
  --colorBackground: #d5bfbf; /*Page Background*/
  --colorSurface: #d5bfbf;
  --colorError: red;
  --colorOnImages: white;
  --colorOnPrimary: black;
  --colorOnSecondary: black;
  --colorOnPrimaryVariant: black;
  --colorOnSecondaryVariant: white;
  --colorOnBackground: black;
  --colorOnSurface: black;
  --colorOnError: black;
  /* ::-webkit-scrollbar {
    display: none;
} */
  --navHeight: 4rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
