.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right : 2rem;
  }
  
  .nav-links li {
    margin: 1rem;
  }
  
  .nav-links a {
    border: 1px solid transparent;
    color: white;
    text-decoration: none;
    padding: 0.5rem;
    font-size: x-large;
  }

.navbar a::before{
    content: '';
    height: 5px;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    transition: .3s;
}

.navbar a:hover::before{
  width: 100%;
}
  
  .nav-links a:hover,
  .nav-links a:active,
  .nav-links a.active {
    
    border-color: transparent;
    color: var(--colorOnSecondaryVariant);
    font-weight: bold;
  }
  
  .nav-links button {
    cursor: pointer;
    border: 1px solid var(--colorOnSecondaryVariant);
  color: var(--colorOnSecondaryVariant);
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links button:focus {
    outline: none;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: var(--colorSecondaryVariant);
    color: var(--colorOnSecondaryVariant);
  }
  
  @media (min-width: 768px) {
    .nav-links {
      flex-direction: row;
    }
  
    .nav-links li {
      margin: 0 0.5rem;
    }
  
    .nav-links a {
      color: var(--colorOnSecondaryVariant);
      text-decoration: none;
      font-size: x-large;
    }
  
    .nav-links button {
      border:none;
      color: var(--colorOnSecondaryVariant);
      background: transparent;
    }
    
    .nav-links button:hover,
    .nav-links button:active {
      background: #6998AB;
      color: #292929;
    }
    
  }
  